/* global Common,CommonApi,$,Const */
/**
 * @file API呼出
 * @author Ozaki Takao
 */
(function () {
  'use select';
  if (typeof window.CommonApi === 'undefined') {
    window.CommonApi = {};
  }

  /**
   * ajax実行
   * ※認証エラーの場合、IDトークンの更新をしてリトライを試みる
   * @access public
   * @param {object} paramSettings ajax設定情報
   * @returns {Promise} Promise ajax実行結果
   */
  CommonApi.ajax = function (paramSettings) {
    return new Promise(function (resolve, reject) {
      // 初回実行
      CommonApi.doAjax(paramSettings).then(resolve).catch(function (data) {
        if (data.status_code === Const.STATUS_CODE.UNAUTHORIXED) {
          // 認証エラーの場合、IDトークンを更新する
          Common.getIdToken().then(function () {
            // リトライ
            CommonApi.doAjax(paramSettings).then(resolve).catch(function (data) {
              if (data.status_code === Const.STATUS_CODE.UNAUTHORIXED) {
                Common.ctrlWhenUnauthorized(['(再リクエスト失敗)']);
                data.message = '';
                reject(data);
              } else {
                reject(data);
              }
            });
          }).catch(function () {
            // IDトークン更新失敗の場合、リトライしない
            Common.ctrlWhenUnauthorized(['(トークン更新失敗)']);
            data.message = '';
            reject(data);
          });
        } else {
          reject(data);
        }
      });
    });
  };
  /**
   * ajax実行（本体）
   * @access public
   * @param {object} paramSettings ajax設定情報
   * @returns {Promise} Promise ajax実行結果
   */
  CommonApi.doAjax = function (paramSettings) {
    return new Promise(function (resolve, reject) {
      // 設定(デフォルト値)
      var settings = {
        type: 'POST',
        headers: {
          'Authorization': Common.getCachedIdToken() // キャッシュされたIDトークンを用いる
        },
        dataType: 'json',
        contentType: 'application/json',
        cache: false
      };
      $.extend(settings, paramSettings, true);
      typeof settings.url === 'string' && (settings.url = Const.API_URL_PREFIX + settings.url); // url にプリフィクス文字を追加
      // ajax実行
      $.ajax(settings).done(function (data, textStatus, jqXHR) {
        // 正常系
        resolve(data);
      }).fail(function (jqXHR, textStatus, errorThrown) {
        // 異常系
        var data = jqXHR.responseJSON || {};
        data.status_code = jqXHR.status;
        if (jqXHR.status === Const.STATUS_CODE.UNAUTHORIXED) {
          // 認証失敗
          reject(data);
        } else if (jqXHR.status === Const.STATUS_CODE.TOO_MANY_REQUESTS) {
          // Too Many Requests の場合
          data.message = Common.MSG.COMMON_ERROR_037;
          reject(data);
        } else if ([
          Const.STATUS_CODE.NOT_FOUND,
          Const.STATUS_CODE.SERVER_ERROR,
          Const.STATUS_CODE.BAD_GATEWAY
        ].includes(jqXHR.status)) {
          // システムエラー画面に遷移する
          Common.pageTransitionToSystemerror();
          reject(data);
        } else if (jqXHR.status === Const.STATUS_CODE.GATEWAY_TIMEOUT) {
          // タイムアウト発生時はポップアップメッセージを表示
          Common.popupCompleteMessage(Common.MSG.COMMON_ERROR_044);
          data.message = null; // API呼出元で返却メッセージを赤枠に表示しないようにする
          reject(data);
        } else if (jqXHR.status >= 500) {
          // システムエラー画面に遷移する
          Common.pageTransitionToSystemerror();
          reject(data);
        } else {
          reject(data);
        }
      });
    });
  };
  /**
   * ajax実行（ファイルアップロード）
   * @access public
   * @param {string} url アップロードURL
   * @param {object} file アップロードファイル
   * @returns {Promise} Promise ajax実行結果
   */
  CommonApi.ajaxForUpload = function (url, file) {
    return new Promise(function (resolve, reject) {
      var settings = {
        type: 'PUT',
        url: url,
        data: file,
        processData: false,
        contentType: 'application/octet-stream',
        cache: false,
        crossDomain: true
      };
      $.ajax(settings).done(function (data, textStatus, jqXHR) {
        resolve(data);
      }).fail(function (jqXHR, textStatus, errorThrown) {
        reject(new Error('アップロードに失敗しました。'));
      });
    });
  };
  /**
   * 該当する情報が見つからないかどうか判定（端末情報、店舗グループ情報、ユーザーグループ情報、ユーザー情報）
   * @access public
   * @param {object} data APIレスポンスデータ
   * @param {string} matchResultCode 検査する結果コード
   * @returns {boolean} 判定結果 ※true: 該当する情報が見つからない
   */
  CommonApi.isDataEmpty = function (data, matchResultCode = Const.RESULT_CODE.NOT_FOUND) {
    // HTTPステータスコードが Bad Request かどうかの判定
    var isStatusCode = data.status_code && (data.status_code === Const.STATUS_CODE.BAD_REQUEST);
    // 結果コードが Not Found かどうかの判定
    var isResultCode = data.result_code && (data.result_code === matchResultCode);
    return isStatusCode && isResultCode;
  };
  /**
   * ユーザー取得API
   * @access public
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getUserInfo = function () {
    var paramSettings = {
      type: 'GET',
      url: '/user'
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * お知らせ一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getInformation = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/information/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザー作成API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.createUser = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/create',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザー更新API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.updateUser = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザー削除API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.deleteUser = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/delete',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * テストメール送信要求API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.sendTestMail = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/mail/test',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 店舗グループ一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getStoreGroupList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/store/group/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末作成API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.createTerminal = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal/create',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTerminal = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末更新API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.updateTerminal = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末削除API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.deleteTerminal = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal/delete',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末設定取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTerminalConfig = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal/config',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末操作取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTerminalControl = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal/control',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末マスタ一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTerminalMasterList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal/master/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ダイレクト再生取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getDirect = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/direct',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ダイレクト再生リスト取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getDirectList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/direct/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ダイレクト再生ファイル作成API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.createDirectFile = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/direct/file/create',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * スケジュールリスト一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getScheduleList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/schedule/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * スケジュールファイル一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getScheduleFileList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/schedule/file/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * スケジュールファイルアップロードURL取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getScheduleUploadUrl = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/schedule/file/upload',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * スケジュールファイルダウンロードURL取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getScheduleDownloadUrl = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/schedule/file/download',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * スケジュールファイル作成API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.createScheduleFile = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/schedule/file/create',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * スケジュールファイル削除API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.deleteScheduleFile = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/schedule/file/delete',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ファームウェア一覧取得API
   * @access public
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getFirmwareList = function () {
    var paramSettings = {
      type: 'GET',
      url: '/firmware/list',
      data: JSON.stringify()
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 店舗グループ所属端末一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTerminalListByStoreGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/store/group/terminal/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 店舗グループ作成API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
  */
  CommonApi.createStoreGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/store/group/create',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 店舗グループ削除API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
  */
  CommonApi.deleteStoreGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/store/group/delete',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 店舗グループ更新API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
  */
  CommonApi.updateStoreGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/store/group/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 店舗グループ所属端末更新API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
  */
  CommonApi.updateTerminalByStoreGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/store/group/terminal/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザーグループ所属端末一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTerminalListByUserGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/group/terminal/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザーグループ所属端末異動API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.updateUserGroupTerminal = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/group/terminal/move',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * タスク一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTaskList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/task/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * タスク作成API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.createTask = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/task/create',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * タスク削除API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.deleteTask = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/task/delete',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザーグループ所属ユーザー一覧取得API
   *
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ユーザーグループ所属ユーザー一覧
   */
  CommonApi.getUserListByUserGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/group/user/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザーグループ一覧取得API
   *
   * @access public
   * @returns {Promise} Promise ユーザーグループ一覧
   */
  CommonApi.getUserGroupList = function () {
    var paramSettings = {
      type: 'GET',
      url: '/user/group/list'
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザーグループ取得API
   *
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ユーザーグループ一覧
   */
  CommonApi.getUserGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/group',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザーグループ作成API
   *
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ユーザーグループ作成結果
   */
  CommonApi.createUserGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/group/create',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザーグループ更新API
   *
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ユーザーグループ更新結果
   */
  CommonApi.updateUserGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/group/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザーグループ削除API
   *
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ユーザーグループ削除結果
   */
  CommonApi.deleteUserGroup = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/group/delete',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザー割当端末一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTerminalListByUser = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/terminal/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * ユーザー割当端末更新API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.updateTerminalListByUser = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/user/terminal/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * タスクエラー一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTaskErrorList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/task/error/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * タスクエラー解決フラグ更新API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.updateTaskErrorSolve = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/task/error/solve/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末異常一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getTerminalErrorList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal/error/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 端末異常解決フラグ更新API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.updateTerminalErrorSolve = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/terminal/error/solve/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 署名付きURL取得(WEB)API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getSignedUrl = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/signedurl',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 課金ログダウンロードURL取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getContentslogDownloadUrl = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/billing/contentslog/download',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 接点履歴一覧取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getContactHistoryList = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/contact/history/list',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 接点通知連動取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.getContactErrorNotification = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/contact/error/notification',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * 接点通知連動更新API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
  CommonApi.updateContactErrorNotification = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/contact/error/notification/update',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
  /**
   * S3オブジェクトメタ情報取得API
   * @access public
   * @param {object} param API呼出の引数
   * @returns {Promise} Promise ajax実行結果(jqXHR)
   */
   CommonApi.getS3ObjectMetadata = function (param) {
    var paramSettings = {
      type: 'POST',
      url: '/s3object/metadata',
      data: JSON.stringify(param)
    };
    return CommonApi.ajax(paramSettings);
  };
}());
